import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from './axiosConfig';
import NotificationSnackbar from '../../components/common/NotificationSnackbar';
import { useSnackbarStore } from '../../store/useSnackBarStore';
import { getProductFruitsData } from '../../util/productFruits';
import { clearLocalStorage } from '../../util';
import InfoSnackbar from '../../components/common/InfoSnackbar';

const SetupInterceptors = (navigate: any, setSnackbarData: any) => {
  axios.interceptors.response.use(
    function (response) {
      const successMessage = response?.data?.message;
      if (successMessage) {
        setSnackbarData(successMessage, 'success');
      }

      return response;
    },
    function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          clearLocalStorage();
          navigate('/login');
        }

        const hasErrorMessage = !error.response.data?.errors?.length && error?.response?.data?.message;
        if (error.response.status === 400 && hasErrorMessage) {
          setSnackbarData(error?.response?.data?.message, 'error');
        }
      }
      return Promise.reject(error);
    }
  );
};

const RootComponents = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const [ran, setRan] = useState(false);
  const dispatch = useSnackbarStore((state) => state.dispatch);

  if (!ran) {
    SetupInterceptors(navigate, (errorMessage: string, severity: 'success' | 'error' | 'warning' | 'info') => {
      dispatch({
        type: 'SET_NOTIFICATION_BAR_DATA',
        payload: {
          open: true,
          message: errorMessage,
          severity
        }
      });
    });
    setRan(true);
  }

  useEffect(() => {
    if (window.productFruits && (window.productFruits as any).identifyUser) {
      (window.productFruits as any).identifyUser(getProductFruitsData());
    }
  }, [location]);

  return (
    <>
      <NotificationSnackbar />
      <InfoSnackbar />
    </>
  );
};

export default RootComponents;
