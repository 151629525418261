import { PaletteColorOptions, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    black: PaletteColorOptions;
    lightBlue: PaletteColorOptions;
    background1: PaletteColorOptions;
    'primary-500': PaletteColorOptions;
  }
  interface PaletteOptions {
    black: PaletteColorOptions;
    lightBlue: PaletteColorOptions;
    background1: PaletteColorOptions;
    'primary-500': PaletteColorOptions;
  }

  interface TypographyVariants {
    smallTextBold: React.CSSProperties;
    tooltip: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    smallTextBold?: React.CSSProperties;
    tooltip?: React.CSSProperties;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    black: true;
  }
}
declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    lightBlue: true;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    smallTextBold: true;
    tooltip: true;
    subtitle: true;
    title2: true;
    mobileTitle2: true;
    h3: false;
    mediumText: true;
    title: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    mediumText: React.CSSProperties;
    subtitle: React.CSSProperties;
    title: React.CSSProperties;
    title2: React.CSSProperties;
    mobileTitle2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mediumText?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    title?: React.CSSProperties;
    title2?: React.CSSProperties;
    mobileTitle2?: React.CSSProperties;
  }
}

// Update the Buttons's variant prop options
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    mainButton: true;
    customButton: true;
  }
}
// Update the TextField's variant prop options
declare module '@mui/material/TextField' {
  interface TextFieldPropsVariantOverrides {
    main: true;
  }
}
// Update the Buttons's variant prop options
declare module '@mui/system/createTheme/createBreakpoints' {
  interface BreakpointOverrides {
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
  breakpoints: {
    values: {
      mobile: 0,
      xs: 0,
      tablet: 600,
      sm: 600,
      md: 900,
      lg: 1200,
      laptop: 1281,
      xl: 1536,
      desktop: 1536
    }
  },
  palette: {
    primary: {
      main: '#186DB1'
    },
    secondary: {
      main: '#793DB5'
    },
    background1: {
      main: '#F7F6F5'
    },
    black: { main: '#D9D9D9' },
    lightBlue: { main: '#30B1E8' },
    'primary-500': {
      main: '#753BBD'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Manrope-Regular',
          textTransform: 'none',
          height: '42px'
        }
      },
      variants: [
        {
          props: { variant: 'mainButton' },
          style: {
            backgroundColor: '#753BBD',
            color: 'white',
            textTransform: 'none',
            height: '42px',
            width: '280px',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: '#753BBD',
              color: 'white',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)'
            },
            '&:disabled': {
              backgroundColor: '#F7F6F5',
              color: '#D9D5CF'
            }
          }
        },
        {
          props: { variant: 'customButton' },
          style: {
            backgroundColor: '#793DB5',
            color: 'white',
            textTransform: 'none',
            height: '42px',
            width: 'auto',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: '#793DB5',
              color: 'white',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)'
            },
            '&:disabled': {
              backgroundColor: '#F7F6F5',
              color: '#D9D5CF'
            }
          }
        }
      ]
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Manrope-Regular',
        variantMapping: {
          // Map the new variant to render a <h1> by default
          smallTextBold: 'h1'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label': {
            top: '4px'
          },
          '& label.Mui-focused': {
            color: '#341358'
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'rgba(0, 0, 0, 0.23)',
            borderWidth: '1px'
          },
          '& .MuiOutlinedInput-root': {
            height: '50px',
            margin: '0 ',
            '&.MuiInputBase-multiline': {
              height: 'auto'
            },
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: '1px'
            },
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: '1px'
            },
            '&.Mui-focused fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: '1px'
            }
          }
        }
      }
    },
    MuiMobileStepper: {
      styleOverrides: {
        dots: {
          display: 'none'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderStyle: 'solid',
          height: '25px',
          fontSize: '12px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Manrope-Regular'
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '.MuiSvgIcon-root.Mui-completed': { color: '#30B1E8 !important' },
          '.MuiSvgIcon-root.Mui-active': { color: '#30B1E8 !important' }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: 'white',
          backgroundColor: 'black'
        }
      }
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            '& label.Mui-focused': {
              color: 'grey'
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'grey'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'grey'
              },
              '&:hover fieldset': {
                borderColor: 'grey'
              },
              '&.Mui-focused fieldset': {
                borderColor: 'grey'
              }
            }
          }
        }
      ]
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& label': {
            top: '4px !important'
          }
        }
      }
    }
  },
  typography: {
    fontFamily: 'Manrope-Regular',
    allVariants: {
      fontFamily: 'Manrope-Regular'
    },
    body1: {
      fontSize: '14px'
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 700
    },
    smallTextBold: {
      fontSize: '14px',
      fontWeight: 700
    },
    mediumText: {
      fontSize: '16px',
      fontWeight: 500
    },
    title: {
      fontSize: '24px',
      fontWeight: 700,
      textAlign: 'center'
    },
    title2: {
      fontSize: '48px',
      fontWeight: 700,
      textAlign: 'center'
    },
    subtitle2: {
      fontSize: '18px',
      fontWeight: 700,
      textAlign: 'center'
    },
    tooltip: {
      fontSize: '10px',
      fontWeight: 500
    },
    mobileTitle2: {
      fontSize: '28px',
      fontWeight: 700,
      color: '#282422',
      textAlign: 'center'
    }
  }
});

theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
});

export default theme;

export const INPUT_STYLE = {
  '.MuiOutlinedInput-notchedOutline, .MuiInputLabel-shrink': { fontSize: '18px !important' },
  '.MuiInputLabel-shrink': {
    top: 'initial',
    color: 'black !important'
  },
  '.MuiInputLabel-root': {
    paddingTop: '5px'
  }
};
