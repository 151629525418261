import { GridSortModel } from '@mui/x-data-grid-pro';
import { Role } from '../constants/common';
import { TokenIndex } from '../interfaces/shared';

export interface VisitorInformation {
  message?: string | null;
  firstName?: string;
  lastName?: string;
  linkedinURL?: string | null;
  email?: string;
  userId?: string | null;
  requestedReviewerFullName?: string;
  requestedReviewerFirstName?: string;
  workHistory?: [
    {
      name: string;
      isSelected: boolean;
    }
  ];
}

const preserveContinuationTokens = (tokens: TokenIndex[], token?: string) => {
  const hasToken = tokens.find((t) => t.token === token);

  if (!hasToken && token) {
    return [...tokens, { index: tokens.length, token }];
  }

  return tokens;
};

const isKannyAdmin = (parameterTokenData?: any): boolean => {
  if (parameterTokenData && parameterTokenData['cognito:groups']) {
    return parameterTokenData['cognito:groups']?.includes('KannyAdmin');
  }

  const userData = localStorage.getItem('userData');
  const tokenData = JSON.parse(userData as string);

  if (tokenData && tokenData['cognito:groups']) {
    return tokenData['cognito:groups']?.includes('KannyAdmin');
  }

  return false;
};

const isInRole = (role: Role): boolean => {
  const userData = localStorage.getItem('userData');
  const tokenData = JSON.parse(userData as string);

  if (tokenData && tokenData['cognito:groups']) {
    return tokenData['cognito:groups']?.includes(role);
  }

  return false;
};

const includesRole = (roles: Role[]): boolean => {
  const userData = localStorage.getItem('userData');
  const tokenData = JSON.parse(userData as string);

  if (tokenData && tokenData['cognito:groups']) {
    return tokenData['cognito:groups']?.find((role: Role) => roles.includes(role));
  }

  return false;
};

const goToPortal = (navigate: any) => {
  if (isInRole('KannyAdmin')) {
    navigate('/kanny-admin-portal/companies');
  } else if (isInRole('Admin') || isInRole('Member')) {
    navigate('/member-portal/candidates');
  } else if (isInRole('User')) {
    navigate('/user-portal/kanny-insights');
  }
};

const getHighestRole = () => {
  if (isInRole('KannyAdmin')) {
    return 'KannyAdmin';
  } else if (isInRole('Admin')) {
    return 'Admin';
  } else if (isInRole('Member')) {
    return 'Member';
  } else if (isInRole('User')) {
    return 'User';
  } else {
    return 'User';
  }
};

const convertRating = (serverRating: number) => {
  // Map the rating from the 0-100 range to 0-5
  const clientRating = (serverRating / 100) * 5;

  // Round the clientRating to the nearest integer
  const roundedRating = Math.round(clientRating);

  return roundedRating;
};

const generateOrderByProps = (model: GridSortModel) => {
  if (model[0]?.sort && model[0]?.field) {
    return {
      property: model[0]?.field,
      ascending: model[0]?.sort === 'asc'
    };
  } else {
    return null;
  }
};

const saveVisitorInformationLocally = (visitorInfo: VisitorInformation) => {
  localStorage.setItem('visitorInformation', JSON.stringify(visitorInfo));
};
const getVisitorInformationLocally = () => JSON.parse(localStorage.getItem('visitorInformation') || '{}');

const openInNewTab = (url: string) => {
  window.open(url, '_blank');
};

export {
  preserveContinuationTokens,
  isInRole,
  isKannyAdmin,
  goToPortal,
  convertRating,
  includesRole,
  generateOrderByProps,
  saveVisitorInformationLocally,
  getVisitorInformationLocally,
  getHighestRole,
  openInNewTab
};
