import { v4 as uuidv4 } from "uuid";

export const getProductFruitsData = () => {
  const currentUrl = window.location.href;
  const urlPath = new URL(currentUrl).pathname;

  let role = '';

  const visitorPattern = /^\/visitor-on-boarding-steps(?:\/.*)?$/;

  const loginPattern = /^\/login(?:\/.*)?$/;
  const loginWithCodePattern = /^\/login\/([^/]+)\/code\/([^/]+)\/?$/;
  const loginWithCodeMatches = urlPath.match(loginWithCodePattern);

  const loginWithJobPattern = /^\/login\/job\/([^/]+)?\/?$/;
  const loginWithJobMatches = urlPath.match(loginWithJobPattern);

  const userPortalPattern = /^\/user-portal(?:\/.*)?$/;
  const memberPortalPattern = /^\/member-portal(?:\/.*)?$/;
  const kannyAdminPortalPattern = /^\/kanny-admin-portal(?:\/.*)?$/;

  const onBoardingPattern = /^\/on-boarding\/([^/]+)(?:\/.*)?$/;
  const onBoardingMatches = urlPath.match(onBoardingPattern);

  const onBoardingStepsPattern = /^\/on-boarding-steps\/step\/([^/]+)\/([^/]+)\/?$/;
  const onBoardingStepsMatches = urlPath.match(onBoardingStepsPattern);

  if (visitorPattern.test(urlPath)) {
    role = 'Visitor';
  } else if (loginWithCodeMatches) {
    role = capitalizeFirstChar(loginWithCodeMatches[1]);
  } else if (loginWithJobMatches) {
    role = 'User';
  } else if (loginPattern.test(urlPath)) {
    role = 'User';
  } else if (userPortalPattern.test(urlPath)) {
    role = 'User';
  } else if (memberPortalPattern.test(urlPath)) {
    role = 'Member';
  } else if (onBoardingMatches) {
    role = capitalizeFirstChar(onBoardingMatches[1]);
  } else if (onBoardingStepsMatches) {
    role = capitalizeFirstChar(onBoardingStepsMatches[2]);
  } else if (kannyAdminPortalPattern) {
    role = "KannyAdmin";
  }

  let productFruitsUserId = localStorage.getItem('productFruitsUserId');
  if (!productFruitsUserId) {
    productFruitsUserId = uuidv4();
    localStorage.setItem('productFruitsUserId', productFruitsUserId);
  }

  return {
    username: productFruitsUserId,
    role: role
  };
}

const capitalizeFirstChar = (input: string): string => {
  if (input.length === 0) {
    return "";
  }

  const firstCharUpper = input.charAt(0).toUpperCase();
  const restOfString = input.slice(1);

  return firstCharUpper + restOfString;
}