import { Alert, Snackbar } from '@mui/material';
import { useSnackbarStore } from '../../../store/useSnackBarStore';

const NotificationSnackbar = () => {
  const storeData = useSnackbarStore((state) => state.notificationBarData);
  const dispatch = useSnackbarStore((state) => state.dispatch);

  return (
    <Snackbar
      sx={{ boxShadow: '1px 1px 15px 1px rgba(0,0,0,0.75)' }}
      open={storeData.open}
      autoHideDuration={6000}
      onClose={() => {
        dispatch({
          type: 'RESET_NOTIFICATION_BAR_DATA'
        });
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert icon={false} severity={storeData.severity}>
        {storeData.message}
      </Alert>
    </Snackbar>
  );
};

export default NotificationSnackbar;
