import { create } from 'zustand';
import { redux } from 'zustand/middleware';


type SnackbarActionTypes =
    | 'SET_NOTIFICATION_BAR_DATA'
    | 'RESET_NOTIFICATION_BAR_DATA'
    | 'SET_INFO_BAR_DATA'
    | 'RESET_INFO_BAR_DATA';

type SnackBarAction = {
    type: SnackbarActionTypes;
    payload?: {
        open: boolean,
        message: string,
        severity: 'success' | 'error' | 'warning' | 'info',
    };
};

type SnackbarState = {
    infoBarData: any;
    notificationBarData: any;
};

const initialSnackBarState: SnackbarState = {
    infoBarData: {
        open: false,
        message: '',
        severity: 'success',
    },
    notificationBarData: {
        open: false,
        message: '',
        severity: 'success',
    }
};

function SnackBarReducer(state: SnackbarState, action: SnackBarAction) {
    switch (action.type) {
        case 'SET_NOTIFICATION_BAR_DATA': {
            return { ...state, notificationBarData: action.payload };
        }
        case 'RESET_NOTIFICATION_BAR_DATA': {
            return { ...state, notificationBarData: initialSnackBarState.notificationBarData };
        }
        case 'SET_INFO_BAR_DATA': {
            return { ...state, infoBarData: action.payload };
        }
        case 'RESET_INFO_BAR_DATA': {
            return { ...state, infoBarData: initialSnackBarState.infoBarData };
        }
        default:
            return state;
    }
}
export const useSnackbarStore = create(redux(SnackBarReducer, initialSnackBarState));
