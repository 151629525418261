import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

import { Box, Button, IconButton, Snackbar, Stack, Typography } from '@mui/material';
import { useSnackbarStore } from '../../../store/useSnackBarStore';

const InfoSnackbar = () => {
  const storeData = useSnackbarStore((state) => state.infoBarData);
  const dispatch = useSnackbarStore((state) => state.dispatch);
  const handleClose = () => {
    dispatch({
      type: 'RESET_INFO_BAR_DATA'
    });
  };
  return (
    <Snackbar
      sx={{
        '.MuiSnackbar-root': {
          backgroundColor: 'transparent'
        }
      }}
      open={storeData.open}
      autoHideDuration={6000}
      onClose={() => {
        dispatch({
          type: 'RESET_INFO_BAR_DATA'
        });
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Stack
        width={'100%'}
        height={'100%'}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ backgroundColor: '#323232', p: '8px 16px', borderRadius: '6px' }}
      >
        <Box mr={'10px'} sx={{paddingTop:'4px'}}>
          <CheckCircleOutlineIcon sx={{ color: '#FFF' }} />
        </Box>
        <Typography sx={{ fontSize: '14px', color: '#FFF' }}>{storeData.message}</Typography>
        <Button color='secondary' size='small' sx={{ color: '#2196F3', fontSize: '13px' }} onClick={handleClose}>
          CLOSE
        </Button>
        <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
          <CloseIcon fontSize='small' style={{ color: '#FFF' }} />
        </IconButton>
      </Stack>
    </Snackbar>
  );
};
export default InfoSnackbar;
